<template>
   <div>
      <ul class="top-list unstyle">
         <li :class="'inspector' + (item.userRating ? ' active':'')" v-for="item in inspectorsData ">
            <div class="inspector-foto photo-responsive">
               <picture>
                  <img v-if="item.photo" onload="this.closest('.photo-responsive').classList.add('loaded')"
                       :src="'https://cdn.riastatic.com/photos/'+item.photo.replace('.jpg','l.jpg')"
                       alt="avatar"/>
                  <img v-else onload="this.closest('.photo-responsive').classList.add('loaded')"
                       :src="'https://cdn.riastatic.com/docs/pictures/common/1/109/10968/10968.png'"
                       alt="avatar"/>
               </picture>
            </div>
            <div class="wrap">
               <div v-if="item.userRating" class="flag red-bg">
                    <svg style="margin-right: 5px;margin-top: -1px;" width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.7059 6.61382C8.56471 6.61382 9.17647 5.81419 9.17647 3.94841C9.17647 1.72724 8.71765 0.394531 6.88235 0.394531C6.11765 0.394531 6.42353 3.23763 6.11765 4.83688C5.50588 8.21306 3.82353 8.39076 3.82353 8.39076H0.764706C0.305882 8.39076 0 8.74614 0 9.27923V15.4985C0 16.0316 0.305882 16.387 0.764706 16.387H3.05882C3.51765 16.387 3.82353 16.0316 3.82353 15.4985C3.82353 15.4985 3.97647 16.387 5.35294 16.387H9.94118C11.2412 16.387 13 12.3889 13 10.1677C13 8.4796 12.8471 6.61382 10.7059 6.61382ZM9.94118 15.4985H5.35294C4.81765 15.4985 4.58824 15.3208 4.58824 15.232C4.51177 14.8766 4.20588 14.61 3.82353 14.61H3.74706C3.36471 14.61 3.05882 15.0543 3.05882 15.4985H0.764706V9.27923H3.05882V13.2773C3.05882 13.5439 3.21176 13.7216 3.44118 13.7216C3.67059 13.7216 3.82353 13.5439 3.82353 13.2773V9.27923H3.9C3.97647 9.27923 6.19412 9.01268 6.88235 5.01457C6.95882 4.3038 7.03529 3.68187 7.03529 2.97109C7.03529 2.43801 7.11176 1.81608 7.18824 1.283C8.02941 1.37185 8.41177 1.90493 8.41177 3.94841C8.41177 4.3038 8.41176 4.57034 8.33529 4.83688C8.18235 5.54765 8.33529 6.25843 8.71765 6.79151C9.25294 7.32459 10.0176 7.59113 10.7059 7.50229C11.9294 7.50229 12.2353 8.03537 12.2353 10.1677C12.2353 12.3 10.5529 15.4985 9.94118 15.4985Z" fill="white"></path>
                    </svg>
                    Так тримати!</div>
               <div class="name size18">{{item.name}}</div>
               <div class="size13"><span class="bold">{{getLevel(item.level)}}</span><span class="dot grey">•</span><span class="bold">{{item.level}}-й</span> рівень<span class="dot grey">•</span><span class="bold">{{formatNumber(item.progress)}}</span> балів</div>
               <div class="date size13">{{mounceIncpect(item.daysInspect)}} інспектує</div>
               <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fauto.ria.com%2Fwho-is-inspector%2F%3Futm_source%3Dfacebook.com%26utm_medium%3Dinspector%26utm_campaign%3Dinspector_head%26r_audience%3Dsmo%26r_source%3Dfacebook%26r_medium%3Dshare%26r_campaign%3Dinspector" class="fb-btn" v-if="item.userRating" @click="gaEvent( 'inspector', 'click_on_share', 'internet_inspector_rating')">
                  <svg style="margin-right: 15px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M10 24H15V12H18L18.5 8H15V6C15 4.9 15.9 4 17 4H19V0H15C12.2 0 10 2.2 10 5V8H7.5V12H10V24Z" fill="white"></path>
                  </svg>
                  Запросити друзів</a>
            </div>
         </li>
      </ul>
   </div>
</template>

<script>



 export default {
     name: "InspectorsList",
     props:{
         inspectorsData: Array
     },
     components: {

     },
     computed: {

     },
     methods: {
         getLevel(level){
             let levelName;
             switch (level) {
                 case 1: levelName = 'Бронзовий';
                    break;
                 case 2: levelName = 'Срібний';
                     break;
                 case 3: levelName = 'Золотий';
                     break;
                 case 4: levelName = 'Платиновий';
                     break;
                 default: levelName = '';
                     break;
             }
             return levelName;
         },
         mounceIncpect(daysInspect = 0){
             if (daysInspect && daysInspect > 29){
                 return Math.floor(daysInspect / 30) + ' міс.'
             }else if(daysInspect && daysInspect <= 29) {
                 return daysInspect + ' д.'
             }else {
                 return ''
             }
         },
     }
 }
</script>

