import _laxios from '../_laxios';
import Vue from "vue";

module.exports = {
    state: Object.assign({
        inspectorsRatings: {},
        listInspectors: [],
        skip: 0,
        block: false
    }, __INITIAL_STATE__.InspectorsRatings),
    actions: {
        getInspectorsRating({commit, rootGetters}, Cookie){
            let headers = {};
            if (Cookie) {
                headers.Cookie = Cookie;
            }

            return _laxios.getInspectorsRating
                .request({headers})
                .then(payload => {
                    commit('inspectorsRatings', { inspectorsRatings: payload});
                    return payload
                })
                .catch(e => {
                    commit('inspectorsRatings', { inspectorsRatings: {}});
                    console.error('getInspectorsRating', e.message);
                    return {}
                });
        },
        getMoreRatings({commit,state},Cookie){
            commit('blockReq', {status: true});
            let headers = {};
            if (Cookie) {
                headers.Cookie = Cookie;
            }

            return _laxios.getMoreRatings
                .request({params: {skip: state.skip},headers})
                .then(payload => {
                    commit('mutationRatingsList', {ratingList: payload});
                    commit('mutationSkip', {skip: state.skip+10});
                    commit('blockReq', {status: false});
                    return payload;
                })
                .catch(error => {
                    commit('blockReq', {status: false});
                    console.error('get getMoreRatings', error.toString());
                })
        }
    },
    mutations: {
        mutationSkip(state, {skip}){
            Vue.set(state, 'skip', skip);
        },
        blockReq(state,{status}){
            Vue.set(state, 'block', status);
        },
        inspectorsRatings(state, {inspectorsRatings})  {
            Vue.set(state, 'inspectorsRatings', inspectorsRatings);
        },
        mutationRatingsList(state, {ratingList}){
           let {listInspectors = []} = state,
               newList = state.listInspectors.concat(ratingList);
           Vue.set(state, 'listInspectors', newList);
        }
    },
    getters: {
        inspectorsRatings({inspectorsRatings}){
            return inspectorsRatings;
        },
        blockReq({block}){
            return block;
        },
        listInspectors({listInspectors}){
            return listInspectors;
        }
    },
    namespaced: true
};


