<template>
   <div class="app-content m-padding">
      <svg width="0" height="0" style="position:absolute">
         <symbol fill="none" viewBox="0 0 60 70" id="level_bronze_active" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#ieclip0)">
               <g filter="url(#iefilter0_d)">
                  <path d="M6.091 8.902c9.64-.188 21.975-6.077 23.704-6.926a.45.45 0 01.41 0c1.729.849 14.064 6.738 23.704 6.926a.474.474 0 01.466.473v10.078c0 20.987-10.34 31.534-24.163 38.565a.468.468 0 01-.424 0C15.966 50.986 5.625 40.44 5.625 19.453V9.375c0-.259.207-.468.466-.473z"
                        fill="#fff"></path>
                  <path d="M6.091 8.902c9.64-.188 21.975-6.077 23.704-6.926a.45.45 0 01.41 0c1.729.849 14.064 6.738 23.704 6.926a.474.474 0 01.466.473v10.078c0 20.987-10.34 31.534-24.163 38.565a.468.468 0 01-.424 0C15.966 50.986 5.625 40.44 5.625 19.453V9.375c0-.259.207-.468.466-.473z"
                        fill="url(#iepaint0_radial)"></path>
               </g>
               <path fill-rule="evenodd" clip-rule="evenodd"
                     d="M30 7.575l.192.087a97.411 97.411 0 007.17 2.883c3.308 1.176 7.359 2.399 11.461 3.047l.396.063v5.798c0 9.593-2.344 16.403-5.961 21.552-3.207 4.564-7.633 8.133-13.029 11.16l-.229.128-.23-.129c-5.395-3.026-9.82-6.595-13.028-11.16-3.617-5.148-5.96-11.958-5.96-21.55v-5.8l.395-.062c4.102-.648 8.152-1.87 11.46-3.047a97.411 97.411 0 007.17-2.883L30 7.575zm0 1.028a98.312 98.312 0 01-7.049 2.825c-3.231 1.15-7.188 2.351-11.232 3.026v5c0 9.428 2.3 16.043 5.79 21.012 3.059 4.353 7.283 7.8 12.491 10.752 5.208-2.953 9.432-6.4 12.49-10.752 3.491-4.969 5.791-11.584 5.791-21.013v-4.999c-4.044-.675-8-1.876-11.233-3.026A98.312 98.312 0 0130 8.604z"
                     fill="#fff" opacity=".5"></path>
               <path fill-rule="evenodd" clip-rule="evenodd"
                     d="M10.781 13.653v5.8c0 9.593 2.344 16.403 5.961 21.552C19.994 45.633 24.5 49.237 30 52.291c5.5-3.054 10.006-6.659 13.258-11.288 3.617-5.148 5.96-11.958 5.96-21.55v-5.8c-4.231-.63-8.436-1.893-11.855-3.109A97.437 97.437 0 0130 7.575a97.42 97.42 0 01-7.363 2.97c-3.42 1.216-7.624 2.478-11.856 3.108zM29.795 1.976c-1.729.849-14.064 6.738-23.704 6.926a.474.474 0 00-.466.473v10.078c0 20.987 10.34 31.534 24.163 38.565.133.067.291.067.424 0 13.822-7.032 24.163-17.578 24.163-38.565V9.375a.474.474 0 00-.466-.473c-9.64-.188-21.975-6.077-23.704-6.926a.45.45 0 00-.41 0z"
                     fill="#000" opacity=".3"></path>
               <g filter="url(#iefilter1_d)">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M9.375 12.423v7.03c0 9.838 2.41 16.941 6.217 22.36C19.138 46.86 24.073 50.697 30 53.896c5.927-3.199 10.862-7.035 14.408-12.083 3.807-5.419 6.217-12.522 6.217-22.36v-7.03c-4.483-.53-9.069-1.88-12.791-3.203A95.818 95.818 0 0130 6.03a95.818 95.818 0 01-7.834 3.19c-3.722 1.324-8.308 2.673-12.791 3.203zm20.42-10.447c-1.729.849-14.064 6.738-23.704 6.926a.474.474 0 00-.466.473v10.078c0 20.987 10.34 31.534 24.163 38.565.133.067.291.067.424 0 13.822-7.032 24.163-17.578 24.163-38.565V9.375a.474.474 0 00-.466-.473c-9.64-.188-21.975-6.077-23.704-6.926a.45.45 0 00-.41 0z"
                        fill="url(#iepaint1_radial)"></path>
               </g>
               <path opacity=".3" fill-rule="evenodd" clip-rule="evenodd"
                     d="M30.168 1c.153.019.305.064.45.135.846.415 4.327 2.083 8.786 3.669 4.476 1.591 9.856 3.07 14.523 3.16a1.411 1.411 0 011.386 1.41v10.08c0 10.656-2.63 18.735-7.07 25.054-4.43 6.307-10.615 10.789-17.606 14.345-.161.082-.334.131-.51.147H30V1h.168z"
                     fill="url(#iepaint2_linear)" fill-opacity=".9"></path>
               <path d="M30 21.563l-9.61 3.75v3.75l9.61-3.75 9.61 3.75v-3.75L30 21.563z" fill="#5E2B18"
                     fill-opacity=".3"></path>
               <path d="M30 21.094l-9.375 3.75v3.75L30 24.844l9.375 3.75v-3.75L30 21.094z" fill="#fff"></path>
               <path d="M39.375 24.844L30 21.094v3.75l9.375 3.75v-3.75z" fill="url(#iepaint3_linear)"></path>
               <path fill-rule="evenodd" clip-rule="evenodd"
                     d="M20.596 4.804c4.459-1.586 7.94-3.254 8.786-3.67a1.388 1.388 0 011.236 0c.846.416 4.327 2.084 8.786 3.67 4.476 1.591 9.856 3.07 14.523 3.16a1.411 1.411 0 011.386 1.41v10.08c0 10.656-2.63 18.735-7.07 25.054-4.43 6.307-10.615 10.789-17.606 14.345l-.425-.835c13.822-7.032 24.163-17.578 24.163-38.565V9.375a.474.474 0 00-.466-.473c-9.64-.188-21.975-6.077-23.704-6.926a.45.45 0 00-.41 0c-1.729.849-14.064 6.738-23.704 6.926a.474.474 0 00-.466.473v10.078c0 20.987 10.34 31.534 24.163 38.565l-.425.835c-6.991-3.556-13.175-8.038-17.607-14.345-4.44-6.32-7.069-14.398-7.069-25.055V9.375c0-.775.622-1.396 1.386-1.41 4.667-.091 10.047-1.57 14.523-3.161zm9.192 53.214c.133.067.291.067.424 0l.425.835c-.4.204-.874.204-1.274 0l.425-.835z"
                     fill="#fff"></path>
            </g>
         </symbol>
         <defs>
            <radialGradient id="iepaint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="matrix(0 44.1797 -38.2891 0 30 13.945)">
               <stop stop-color="#FEC280"></stop>
               <stop offset=".844" stop-color="#9B512E"></stop>
            </radialGradient>
            <radialGradient id="iepaint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="matrix(0 54.0234 -46.8203 0 30 4.102)">
               <stop stop-color="#BF7D5A"></stop>
               <stop offset="1" stop-color="#8F4B32"></stop>
            </radialGradient>
            <linearGradient id="iepaint2_linear" x1="42.656" y1="1" x2="42.656" y2="59"
                            gradientUnits="userSpaceOnUse">
               <stop stop-color="#FEC280" stop-opacity=".25"></stop>
               <stop offset="1" stop-color="#551B00"></stop>
            </linearGradient>
            <linearGradient id="iepaint3_linear" x1="34.688" y1="21.094" x2="34.688" y2="28.594"
                            gradientUnits="userSpaceOnUse">
               <stop stop-color="#E0E3E4"></stop>
               <stop offset="1" stop-color="#E0E3E4" stop-opacity="0"></stop>
            </linearGradient>
            <filter id="iefilter0_d" x="-2.375" y="-2.073" width="64.75" height="72.141" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
               <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
               <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
               <feOffset dy="4"></feOffset>
               <feGaussianBlur stdDeviation="4"></feGaussianBlur>
               <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
               <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
               <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
            </filter>
            <filter id="iefilter1_d" x="3.625" y=".927" width="52.75" height="60.141" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
               <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
               <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
               <feOffset dy="1"></feOffset>
               <feGaussianBlur stdDeviation="1"></feGaussianBlur>
               <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
               <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
               <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
            </filter>
            <clipPath id="ieclip0">
               <path fill="#fff" d="M0 0h60v70H0z"></path>
            </clipPath>
         </defs>
         <symbol fill="none" viewBox="0 0 60 70" id="level_silver_active" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#ihclip0)">
               <g filter="url(#ihfilter0_d)">
                  <path d="M6.091 8.902c9.64-.188 21.975-6.077 23.704-6.926a.45.45 0 01.41 0c1.729.849 14.064 6.738 23.704 6.926a.474.474 0 01.466.473v10.078c0 20.987-10.34 31.534-24.163 38.565a.468.468 0 01-.424 0C15.966 50.986 5.625 40.44 5.625 19.453V9.375c0-.259.207-.468.466-.473z"
                        fill="#fff"></path>
                  <path d="M6.091 8.902c9.64-.188 21.975-6.077 23.704-6.926a.45.45 0 01.41 0c1.729.849 14.064 6.738 23.704 6.926a.474.474 0 01.466.473v10.078c0 20.987-10.34 31.534-24.163 38.565a.468.468 0 01-.424 0C15.966 50.986 5.625 40.44 5.625 19.453V9.375c0-.259.207-.468.466-.473z"
                        fill="url(#ihpaint0_radial)"></path>
               </g>
               <path fill-rule="evenodd" clip-rule="evenodd"
                     d="M30 7.575l.192.087a97.411 97.411 0 007.17 2.883c3.308 1.176 7.359 2.399 11.461 3.047l.396.063v5.798c0 9.593-2.344 16.403-5.961 21.552-3.207 4.564-7.633 8.133-13.029 11.16l-.229.128-.23-.129c-5.395-3.026-9.82-6.595-13.028-11.16-3.617-5.148-5.96-11.958-5.96-21.55v-5.8l.395-.062c4.102-.648 8.152-1.87 11.46-3.047a97.411 97.411 0 007.17-2.883L30 7.575zm0 1.028a98.312 98.312 0 01-7.049 2.825c-3.231 1.15-7.188 2.351-11.232 3.026v5c0 9.428 2.3 16.043 5.79 21.012 3.059 4.353 7.283 7.8 12.491 10.752 5.208-2.953 9.432-6.4 12.49-10.752 3.491-4.969 5.791-11.584 5.791-21.013v-4.999c-4.044-.675-8-1.876-11.233-3.026A98.312 98.312 0 0130 8.604z"
                     fill="#fff" opacity=".5"></path>
               <path fill-rule="evenodd" clip-rule="evenodd"
                     d="M10.781 13.653v5.8c0 9.593 2.344 16.403 5.961 21.552C19.994 45.633 24.5 49.237 30 52.291c5.5-3.054 10.006-6.659 13.258-11.288 3.617-5.148 5.96-11.958 5.96-21.55v-5.8c-4.231-.63-8.436-1.893-11.855-3.109A97.437 97.437 0 0130 7.575a97.42 97.42 0 01-7.363 2.97c-3.42 1.216-7.624 2.478-11.856 3.108zM29.795 1.976c-1.729.849-14.064 6.738-23.704 6.926a.474.474 0 00-.466.473v10.078c0 20.987 10.34 31.534 24.163 38.565.133.067.291.067.424 0 13.822-7.032 24.163-17.578 24.163-38.565V9.375a.474.474 0 00-.466-.473c-9.64-.188-21.975-6.077-23.704-6.926a.45.45 0 00-.41 0z"
                     fill="#000" opacity=".3"></path>
               <g filter="url(#ihfilter1_d)">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M9.375 12.423v7.03c0 9.838 2.41 16.941 6.217 22.36C19.138 46.86 24.073 50.697 30 53.896c5.927-3.199 10.862-7.035 14.408-12.083 3.807-5.419 6.217-12.522 6.217-22.36v-7.03c-4.483-.53-9.069-1.88-12.791-3.203A95.818 95.818 0 0130 6.03a95.818 95.818 0 01-7.834 3.19c-3.722 1.324-8.308 2.673-12.791 3.203zm20.42-10.447c-1.729.849-14.064 6.738-23.704 6.926a.474.474 0 00-.466.473v10.078c0 20.987 10.34 31.534 24.163 38.565.133.067.291.067.424 0 13.822-7.032 24.163-17.578 24.163-38.565V9.375a.474.474 0 00-.466-.473c-9.64-.188-21.975-6.077-23.704-6.926a.45.45 0 00-.41 0z"
                        fill="url(#ihpaint1_radial)"></path>
               </g>
               <path opacity=".3" fill-rule="evenodd" clip-rule="evenodd"
                     d="M30.168 1c.153.019.305.064.45.135.846.415 4.327 2.083 8.786 3.669 4.476 1.591 9.856 3.07 14.523 3.16a1.411 1.411 0 011.386 1.41v10.08c0 10.656-2.63 18.735-7.07 25.054-4.43 6.307-10.615 10.789-17.606 14.345-.161.082-.334.131-.51.147H30V1h.168z"
                     fill="url(#ihpaint2_linear)" fill-opacity=".9"></path>
               <path d="M30 24.563l-9.61 3.75v3.75l9.61-3.75 9.61 3.75v-3.75L30 24.563z" fill="#767778"></path>
               <path d="M30 24.094l-9.375 3.75v3.75L30 27.844l9.375 3.75v-3.75L30 24.094z" fill="#fff"></path>
               <path d="M39.375 27.844L30 24.094v3.75l9.375 3.75v-3.75z" fill="url(#ihpaint3_linear)"></path>
               <path d="M30 18.563l-9.61 3.75v3.75l9.61-3.75 9.61 3.75v-3.75L30 18.563z" fill="#767778"></path>
               <path d="M30 18.094l-9.375 3.75v3.75L30 21.844l9.375 3.75v-3.75L30 18.094z" fill="#fff"></path>
               <path d="M39.375 21.844L30 18.094v3.75l9.375 3.75v-3.75z" fill="url(#ihpaint4_linear)"></path>
               <path fill-rule="evenodd" clip-rule="evenodd"
                     d="M20.596 4.804c4.459-1.586 7.94-3.254 8.786-3.67a1.388 1.388 0 011.236 0c.846.416 4.327 2.084 8.786 3.67 4.476 1.591 9.856 3.07 14.523 3.16a1.411 1.411 0 011.386 1.41v10.08c0 10.656-2.63 18.735-7.07 25.054-4.43 6.307-10.615 10.789-17.606 14.345l-.425-.835c13.822-7.032 24.163-17.578 24.163-38.565V9.375a.474.474 0 00-.466-.473c-9.64-.188-21.975-6.077-23.704-6.926a.45.45 0 00-.41 0c-1.729.849-14.064 6.738-23.704 6.926a.474.474 0 00-.466.473v10.078c0 20.987 10.34 31.534 24.163 38.565l-.425.835c-6.991-3.556-13.175-8.038-17.607-14.345-4.44-6.32-7.069-14.398-7.069-25.055V9.375c0-.775.622-1.396 1.386-1.41 4.667-.091 10.047-1.57 14.523-3.161zm9.192 53.214c.133.067.291.067.424 0l.425.835c-.4.204-.874.204-1.274 0l.425-.835z"
                     fill="#fff"></path>
            </g>
         </symbol>
         <defs>
            <linearGradient id="ihpaint2_linear" x1="42.656" y1="1" x2="42.656" y2="59"
                            gradientUnits="userSpaceOnUse">
               <stop stop-color="#E0E3E4" stop-opacity=".5"></stop>
               <stop offset="1" stop-opacity=".75"></stop>
            </linearGradient>
            <linearGradient id="ihpaint3_linear" x1="34.688" y1="24.094" x2="34.688" y2="31.594"
                            gradientUnits="userSpaceOnUse">
               <stop stop-color="#E0E3E4"></stop>
               <stop offset="1" stop-color="#E0E3E4" stop-opacity="0"></stop>
            </linearGradient>
            <linearGradient id="ihpaint4_linear" x1="34.688" y1="18.094" x2="34.688" y2="25.594"
                            gradientUnits="userSpaceOnUse">
               <stop stop-color="#E0E3E4"></stop>
               <stop offset="1" stop-color="#E0E3E4" stop-opacity="0"></stop>
            </linearGradient>
            <radialGradient id="ihpaint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="matrix(0 44.1797 -38.2891 0 30 13.945)">
               <stop stop-color="#E0E3E4"></stop>
               <stop offset=".844" stop-color="#9B9B9B"></stop>
            </radialGradient>
            <radialGradient id="ihpaint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="matrix(0 54.0234 -46.8203 0 30 4.102)">
               <stop stop-color="#EAEAEA"></stop>
               <stop offset="1" stop-color="#A3A3A3"></stop>
            </radialGradient>
            <filter id="ihfilter0_d" x="-2.375" y="-2.073" width="64.75" height="72.141" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
               <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
               <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
               <feOffset dy="4"></feOffset>
               <feGaussianBlur stdDeviation="4"></feGaussianBlur>
               <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
               <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
               <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
            </filter>
            <filter id="ihfilter1_d" x="3.625" y=".927" width="52.75" height="60.141" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
               <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
               <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
               <feOffset dy="1"></feOffset>
               <feGaussianBlur stdDeviation="1"></feGaussianBlur>
               <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
               <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
               <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
            </filter>
            <clipPath id="ihclip0">
               <path fill="#fff" d="M0 0h60v70H0z"></path>
            </clipPath>
         </defs>
         <symbol fill="none" viewBox="0 0 60 70" id="level_gold_active" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#ifclip0)">
               <g filter="url(#iffilter0_d)">
                  <path d="M6.091 8.953c9.64-.19 21.975-6.112 23.704-6.965a.448.448 0 01.41 0c1.729.853 14.064 6.776 23.704 6.965a.475.475 0 01.466.476v10.135c0 21.107-10.34 31.714-24.163 38.785a.466.466 0 01-.424 0C15.966 51.278 5.625 40.671 5.625 19.564V9.43c0-.26.207-.471.466-.476z"
                        fill="#fff"></path>
                  <path d="M6.091 8.953c9.64-.19 21.975-6.112 23.704-6.965a.448.448 0 01.41 0c1.729.853 14.064 6.776 23.704 6.965a.475.475 0 01.466.476v10.135c0 21.107-10.34 31.714-24.163 38.785a.466.466 0 01-.424 0C15.966 51.278 5.625 40.671 5.625 19.564V9.43c0-.26.207-.471.466-.476z"
                        fill="url(#ifpaint0_radial)"></path>
               </g>
               <path fill-rule="evenodd" clip-rule="evenodd"
                     d="M30 7.619l.192.086a97.085 97.085 0 007.17 2.9c3.308 1.183 7.359 2.413 11.461 3.065l.396.063v5.831c0 9.648-2.344 16.497-5.961 21.675-3.207 4.59-7.633 8.18-13.029 11.224l-.229.129-.23-.13c-5.395-3.043-9.82-6.632-13.028-11.223-3.617-5.178-5.96-12.027-5.96-21.675v-5.831l.395-.063c4.102-.652 8.152-1.882 11.46-3.065a97.085 97.085 0 007.17-2.9L30 7.62zm0 1.033a97.965 97.965 0 01-7.049 2.842c-3.231 1.155-7.188 2.364-11.232 3.043v5.027c0 9.483 2.3 16.136 5.79 21.133C20.568 45.075 24.792 48.54 30 51.51c5.208-2.969 9.432-6.435 12.49-10.813 3.491-4.997 5.791-11.65 5.791-21.133v-5.027c-4.044-.68-8-1.888-11.233-3.043A97.965 97.965 0 0130 8.652z"
                     fill="#fff" opacity=".5"></path>
               <path fill-rule="evenodd" clip-rule="evenodd"
                     d="M10.781 13.731v5.833c0 9.648 2.344 16.497 5.961 21.675C19.994 45.894 24.5 49.519 30 52.59c5.5-3.071 10.006-6.697 13.258-11.352 3.617-5.178 5.96-12.027 5.96-21.675v-5.833c-4.231-.633-8.436-1.903-11.855-3.126A97.12 97.12 0 0130 7.618a97.103 97.103 0 01-7.363 2.987c-3.42 1.223-7.624 2.493-11.856 3.126zM29.795 1.988c-1.729.853-14.064 6.776-23.704 6.965a.475.475 0 00-.466.476v10.135c0 21.107 10.34 31.714 24.163 38.785.133.068.291.068.424 0 13.822-7.071 24.163-17.678 24.163-38.785V9.43a.475.475 0 00-.466-.476c-9.64-.19-21.975-6.112-23.704-6.965a.448.448 0 00-.41 0z"
                     fill="#000" opacity=".3"></path>
               <g filter="url(#iffilter1_d)">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M9.375 12.494v7.07c0 9.895 2.41 17.038 6.217 22.488 3.546 5.077 8.481 8.935 14.408 12.152 5.927-3.217 10.862-7.075 14.408-12.152 3.807-5.45 6.217-12.593 6.217-22.488v-7.07c-4.483-.533-9.069-1.89-12.791-3.221A95.506 95.506 0 0130 6.063a95.506 95.506 0 01-7.834 3.21c-3.722 1.331-8.308 2.688-12.791 3.22zm20.42-10.506c-1.729.853-14.064 6.776-23.704 6.965a.475.475 0 00-.466.476v10.135c0 21.107 10.34 31.714 24.163 38.785.133.068.291.068.424 0 13.822-7.071 24.163-17.678 24.163-38.785V9.43a.475.475 0 00-.466-.476c-9.64-.19-21.975-6.112-23.704-6.965a.448.448 0 00-.41 0z"
                        fill="url(#ifpaint1_radial)"></path>
               </g>
               <path opacity=".3" fill-rule="evenodd" clip-rule="evenodd"
                     d="M30.168 1c.153.019.305.064.45.135.846.415 4.327 2.083 8.786 3.669 4.476 1.591 9.856 3.07 14.523 3.16a1.411 1.411 0 011.386 1.41v10.08c0 10.656-2.63 18.735-7.07 25.054-4.43 6.307-10.615 10.789-17.606 14.345-.161.082-.334.131-.51.147H30V1h.168z"
                     fill="url(#ifpaint2_linear)" fill-opacity=".9"></path>
               <path d="M30 21.686l-9.61 3.771v3.771l9.61-3.77 9.61 3.77v-3.77L30 21.685z" fill="#5E2B18"
                     fill-opacity=".3"></path>
               <path d="M30 21.214l-9.375 3.772v3.771L30 24.986l9.375 3.771v-3.771L30 21.214z" fill="#fff"></path>
               <path d="M39.375 24.986L30 21.214v3.772l9.375 3.771v-3.771z" fill="#fff"></path>
               <path d="M39.375 24.986L30 21.214v3.772l9.375 3.771v-3.771z" fill="url(#ifpaint3_linear)"></path>
               <path d="M30 27.72l-9.61 3.771v3.772L30 31.49l9.61 3.772V31.49L30 27.72z" fill="#5E2B18"
                     fill-opacity=".3"></path>
               <path d="M30 27.248l-9.375 3.772v3.771L30 31.02l9.375 3.771V31.02L30 27.249z" fill="#fff"></path>
               <path d="M39.375 31.02L30 27.249v3.771l9.375 3.771V31.02z" fill="#fff"></path>
               <path d="M39.375 31.02L30 27.249v3.771l9.375 3.771V31.02z" fill="url(#ifpaint4_linear)"></path>
               <path d="M30 15.651l-9.61 3.772v3.771L30 19.423l9.61 3.771v-3.771L30 15.65z" fill="#5E2B18"
                     fill-opacity=".3"></path>
               <path d="M30 15.18l-9.375 3.771v3.772L30 18.95l9.375 3.772V18.95L30 15.18z" fill="#fff"></path>
               <path d="M39.375 18.951L30 15.18v3.771l9.375 3.772V18.95z" fill="#fff"></path>
               <path d="M39.375 18.951L30 15.18v3.771l9.375 3.772V18.95z" fill="url(#ifpaint5_linear)"></path>
               <path fill-rule="evenodd" clip-rule="evenodd"
                     d="M20.596 4.831c4.459-1.595 7.94-3.272 8.786-3.69a1.38 1.38 0 011.236 0c.846.418 4.327 2.096 8.786 3.69 4.476 1.6 9.856 3.088 14.523 3.179.764.015 1.386.64 1.386 1.419v10.135c0 10.718-2.63 18.843-7.07 25.198-4.43 6.343-10.615 10.85-17.606 14.428l-.425-.84c13.822-7.072 24.163-17.679 24.163-38.786V9.43a.475.475 0 00-.466-.476c-9.64-.19-21.975-6.112-23.704-6.965a.448.448 0 00-.41 0c-1.729.853-14.064 6.776-23.704 6.965a.475.475 0 00-.466.476v10.135c0 21.107 10.34 31.714 24.163 38.785l-.425.84c-6.991-3.576-13.175-8.084-17.607-14.427-4.44-6.355-7.069-14.48-7.069-25.198V9.43c0-.78.622-1.404 1.386-1.42 4.667-.09 10.047-1.577 14.523-3.178zm9.192 53.518c.133.068.291.068.424 0l.425.84c-.4.205-.874.205-1.274 0l.425-.84z"
                     fill="#fff"></path>
            </g>
         </symbol>
         <defs>
            <linearGradient id="ifpaint2_linear" x1="42.656" y1="1" x2="42.656" y2="59"
                            gradientUnits="userSpaceOnUse">
               <stop stop-color="#FCA657" stop-opacity=".5"></stop>
               <stop offset="1" stop-color="#93631C" stop-opacity=".9"></stop>
            </linearGradient>
            <linearGradient id="ifpaint3_linear" x1="34.688" y1="21.214" x2="34.688" y2="28.757"
                            gradientUnits="userSpaceOnUse">
               <stop stop-color="#E0E3E4"></stop>
               <stop offset="1" stop-color="#E0E3E4" stop-opacity="0"></stop>
            </linearGradient>
            <linearGradient id="ifpaint4_linear" x1="34.688" y1="27.248" x2="34.688" y2="34.791"
                            gradientUnits="userSpaceOnUse">
               <stop stop-color="#E0E3E4"></stop>
               <stop offset="1" stop-color="#E0E3E4" stop-opacity="0"></stop>
            </linearGradient>
            <linearGradient id="ifpaint5_linear" x1="34.688" y1="15.18" x2="34.688" y2="22.723"
                            gradientUnits="userSpaceOnUse">
               <stop stop-color="#E0E3E4"></stop>
               <stop offset="1" stop-color="#E0E3E4" stop-opacity="0"></stop>
            </linearGradient>
            <radialGradient id="ifpaint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="matrix(0 44.3461 -38.2891 0 30 14.054)">
               <stop stop-color="#FCE257"></stop>
               <stop offset=".844" stop-color="#EAB634"></stop>
            </radialGradient>
            <radialGradient id="ifpaint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="matrix(0 54.2269 -46.8203 0 30 4.173)">
               <stop stop-color="#ECBB38"></stop>
               <stop offset="1" stop-color="#DBA820"></stop>
            </radialGradient>
            <filter id="iffilter0_d" x="-2.375" y="-2.062" width="64.75" height="72.462" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
               <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
               <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
               <feOffset dy="4"></feOffset>
               <feGaussianBlur stdDeviation="4"></feGaussianBlur>
               <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
               <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
               <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
            </filter>
            <filter id="iffilter1_d" x="3.625" y=".938" width="52.75" height="60.462" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
               <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
               <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
               <feOffset dy="1"></feOffset>
               <feGaussianBlur stdDeviation="1"></feGaussianBlur>
               <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
               <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
               <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
            </filter>
            <clipPath id="ifclip0">
               <path fill="#fff" d="M0 0h60v70H0z"></path>
            </clipPath>
         </defs>
         <symbol fill="none" viewBox="0 0 60 70" id="level_platinum_active" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#igclip0)">
               <g filter="url(#igfilter0_d)">
                  <path d="M6.091 8.902c9.64-.188 21.975-6.077 23.704-6.926a.45.45 0 01.41 0c1.729.849 14.064 6.738 23.704 6.926a.474.474 0 01.466.473v10.078c0 20.987-10.34 31.534-24.163 38.565a.468.468 0 01-.424 0C15.966 50.986 5.625 40.44 5.625 19.453V9.375c0-.259.207-.468.466-.473z"
                        fill="#fff"></path>
                  <path d="M6.091 8.902c9.64-.188 21.975-6.077 23.704-6.926a.45.45 0 01.41 0c1.729.849 14.064 6.738 23.704 6.926a.474.474 0 01.466.473v10.078c0 20.987-10.34 31.534-24.163 38.565a.468.468 0 01-.424 0C15.966 50.986 5.625 40.44 5.625 19.453V9.375c0-.259.207-.468.466-.473z"
                        fill="url(#igpaint0_radial)"></path>
               </g>
               <path fill-rule="evenodd" clip-rule="evenodd"
                     d="M30 7.575l.192.087a97.43 97.43 0 007.17 2.883c3.308 1.176 7.359 2.399 11.461 3.047l.396.063v5.798c0 9.593-2.344 16.403-5.961 21.552-3.207 4.564-7.633 8.133-13.029 11.16l-.229.128-.23-.129c-5.395-3.026-9.82-6.595-13.028-11.16-3.617-5.148-5.96-11.958-5.96-21.55v-5.8l.395-.062c4.102-.648 8.152-1.87 11.46-3.047a97.411 97.411 0 007.17-2.883L30 7.575zm0 1.028a98.312 98.312 0 01-7.049 2.825c-3.231 1.15-7.188 2.351-11.232 3.026v5c0 9.428 2.3 16.043 5.79 21.012 3.059 4.353 7.283 7.8 12.491 10.752 5.208-2.953 9.432-6.4 12.49-10.752 3.491-4.969 5.791-11.584 5.791-21.013v-4.999c-4.044-.675-8-1.876-11.233-3.026A98.312 98.312 0 0130 8.604z"
                     fill="#fff" opacity=".5"></path>
               <path fill-rule="evenodd" clip-rule="evenodd"
                     d="M10.781 13.653v5.8c0 9.593 2.344 16.403 5.961 21.552C19.994 45.633 24.5 49.238 30 52.292c5.5-3.054 10.006-6.659 13.258-11.287 3.617-5.149 5.96-11.96 5.96-21.552v-5.8c-4.231-.63-8.436-1.892-11.855-3.108A97.415 97.415 0 0130 7.575a97.397 97.397 0 01-7.363 2.97c-3.42 1.216-7.624 2.478-11.856 3.108zM29.795 1.976c-1.729.849-14.064 6.738-23.704 6.926a.474.474 0 00-.466.473v10.078c0 20.987 10.34 31.534 24.163 38.565.133.067.291.067.424 0 13.822-7.032 24.163-17.578 24.163-38.565V9.375a.474.474 0 00-.466-.473c-9.64-.188-21.975-6.077-23.704-6.926a.45.45 0 00-.41 0z"
                     fill="#000" opacity=".35"></path>
               <g filter="url(#igfilter1_d)">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M9.375 12.423v7.03c0 9.838 2.41 16.942 6.217 22.36 3.546 5.048 8.481 8.884 14.408 12.083 5.927-3.199 10.862-7.035 14.408-12.083 3.807-5.419 6.217-12.522 6.217-22.36v-7.03c-4.483-.53-9.069-1.88-12.791-3.203A95.818 95.818 0 0130 6.03a95.818 95.818 0 01-7.834 3.19c-3.722 1.324-8.308 2.673-12.791 3.203zm20.42-10.447c-1.729.849-14.064 6.738-23.704 6.926a.474.474 0 00-.466.473v10.078c0 20.987 10.34 31.534 24.163 38.565.133.067.291.067.424 0 13.822-7.032 24.163-17.578 24.163-38.565V9.375a.474.474 0 00-.466-.473c-9.64-.188-21.975-6.077-23.704-6.926a.45.45 0 00-.41 0z"
                        fill="url(#igpaint1_radial)"></path>
               </g>
               <path d="M6.091 8.902c9.64-.188 21.975-6.077 23.704-6.926a.45.45 0 01.41 0c1.729.849 14.064 6.738 23.704 6.926a.474.474 0 01.466.473v10.078c0 20.987-10.34 31.534-24.163 38.565a.468.468 0 01-.424 0C15.966 50.986 5.625 40.44 5.625 19.453V9.375c0-.259.207-.468.466-.473z"
                     fill="url(#igpaint2_radial)" opacity=".1"></path>
               <path opacity=".3" fill-rule="evenodd" clip-rule="evenodd"
                     d="M30.168 1c.153.019.305.064.45.135.846.415 4.327 2.083 8.786 3.669 4.476 1.591 9.856 3.07 14.523 3.16a1.411 1.411 0 011.386 1.41v10.08c0 10.656-2.63 18.735-7.07 25.054-4.43 6.307-10.615 10.789-17.606 14.345-.161.082-.334.131-.51.147H30V1h.168z"
                     fill="url(#igpaint3_linear)" fill-opacity=".9"></path>
               <path d="M29.651 17.005a.4.4 0 01.697 0l2.754 4.886a.4.4 0 00.27.195l5.497 1.11a.4.4 0 01.215.662l-3.795 4.129a.4.4 0 00-.103.316l.644 5.571a.4.4 0 01-.564.41l-5.1-2.334a.4.4 0 00-.332 0l-5.1 2.334a.4.4 0 01-.564-.41l.644-5.57a.4.4 0 00-.103-.317l-3.795-4.129a.4.4 0 01.215-.663l5.497-1.109a.4.4 0 00.27-.195l2.753-4.886z"
                     fill="#9498BA"></path>
               <path d="M29.651 16.618a.4.4 0 01.697 0l2.754 4.886a.4.4 0 00.27.195l5.497 1.11a.4.4 0 01.215.662L35.29 27.6a.4.4 0 00-.103.317l.644 5.57a.4.4 0 01-.564.41l-5.1-2.334a.4.4 0 00-.332 0l-5.1 2.334a.4.4 0 01-.564-.41l.644-5.57a.4.4 0 00-.103-.317l-3.795-4.129a.4.4 0 01.215-.662l5.497-1.11a.4.4 0 00.27-.195l2.753-4.886z"
                     fill="#fff"></path>
               <path d="M33.102 21.504l-2.353-4.176c-.203-.358-.749-.215-.749.196V31.23a.4.4 0 00.233.364l5.033 2.303a.4.4 0 00.564-.41l-.644-5.57a.4.4 0 01.103-.317l3.795-4.129a.4.4 0 00-.215-.662l-5.498-1.11a.4.4 0 01-.269-.195z"
                     fill="url(#igpaint4_linear)"></path>
               <path fill-rule="evenodd" clip-rule="evenodd"
                     d="M20.596 4.804c4.459-1.586 7.94-3.254 8.786-3.67a1.388 1.388 0 011.236 0c.846.416 4.327 2.084 8.786 3.67 4.476 1.591 9.856 3.07 14.523 3.16a1.411 1.411 0 011.386 1.411v10.078c0 10.657-2.63 18.736-7.07 25.055-4.43 6.307-10.615 10.789-17.606 14.345l-.425-.835c13.822-7.032 24.163-17.578 24.163-38.565V9.375a.474.474 0 00-.466-.473c-9.64-.188-21.975-6.077-23.704-6.926a.45.45 0 00-.41 0c-1.729.849-14.064 6.738-23.704 6.926a.474.474 0 00-.466.473v10.078c0 20.987 10.34 31.534 24.163 38.565l-.425.835c-6.991-3.556-13.175-8.038-17.607-14.345-4.44-6.32-7.069-14.398-7.069-25.055V9.375c0-.775.622-1.396 1.386-1.41 4.667-.091 10.047-1.57 14.523-3.161zm9.192 53.214c.133.067.291.067.424 0l.425.835c-.4.204-.874.204-1.274 0l.425-.835z"
                     fill="#fff"></path>
            </g>
         </symbol>
         <defs>
            <radialGradient id="igpaint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="matrix(0 44.1797 -38.2891 0 30 13.945)">
               <stop stop-color="#E1E6F0"></stop>
               <stop offset=".844" stop-color="#BEC7D7"></stop>
            </radialGradient>
            <radialGradient id="igpaint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="matrix(0 54.0234 -46.8203 0 30 4.102)">
               <stop stop-color="#ECF2FF"></stop>
               <stop offset="1" stop-color="#ABADB7"></stop>
            </radialGradient>
            <radialGradient id="igpaint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="matrix(0 51.6249 -44.7416 0 30 6.5)">
               <stop offset=".333" stop-color="#00C2FF"></stop>
               <stop offset="1" stop-color="#8F00FF"></stop>
            </radialGradient>
            <linearGradient id="igpaint3_linear" x1="42.656" y1="1" x2="42.656" y2="59"
                            gradientUnits="userSpaceOnUse">
               <stop stop-color="#E1E6F0" stop-opacity=".5"></stop>
               <stop offset="1" stop-color="#13274F"></stop>
            </linearGradient>
            <linearGradient id="igpaint4_linear" x1="34.782" y1="16" x2="34.782" y2="34.193"
                            gradientUnits="userSpaceOnUse">
               <stop stop-color="#E0E3E4"></stop>
               <stop offset="1" stop-color="#E0E3E4" stop-opacity="0"></stop>
            </linearGradient>
            <filter id="igfilter0_d" x="-2.375" y="-2.073" width="64.75" height="72.141" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
               <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
               <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
               <feOffset dy="4"></feOffset>
               <feGaussianBlur stdDeviation="4"></feGaussianBlur>
               <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
               <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
               <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
            </filter>
            <filter id="igfilter1_d" x="3.625" y=".927" width="52.75" height="60.141" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
               <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
               <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
               <feOffset dy="1"></feOffset>
               <feGaussianBlur stdDeviation="1"></feGaussianBlur>
               <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"></feColorMatrix>
               <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
               <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
            </filter>
            <clipPath id="igclip0">
               <path fill="#fff" d="M0 0h60v70H0z"></path>
            </clipPath>
         </defs>
      </svg>
      <h1 class="h size18 bold">Рейтинг Інтернет-інспекторів AUTO.RIA</h1>
      <div class="header-wrap">
         <svg width="78" height="73" viewBox="0 0 78 73" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48.6891 37.0327C51.7186 34.2124 53.7333 29.3562 53.7333 24.5C53.7333 18.701 48.9279 14 43 14C37.0721 14 32.2667 18.701 32.2667 24.5C32.2667 29.3562 34.2814 34.2124 37.3109 37.0327L21.6952 44.6708C20.6563 45.179 20 46.2178 20 47.3541V53C20 56.3137 22.746 59 26.1333 59H59.8667C63.254 59 66 56.3137 66 53V47.3541C66 46.2178 65.3437 45.179 64.3048 44.6708L48.6891 37.0327Z" fill="#256799" fill-opacity="0.2"/>
            <path d="M15.545 39.5379L27.8106 33.2709C27.8116 33.2703 27.8135 33.2694 27.818 33.2689C27.8231 33.2682 27.8324 33.2679 27.8458 33.2706C27.8749 33.2763 27.9081 33.2931 27.9341 33.32C29.1233 34.5495 32.1344 37.1387 36.5 37.1387C40.8656 37.1387 43.8767 34.5495 45.0659 33.32C45.0919 33.2931 45.1251 33.2763 45.1542 33.2706C45.1675 33.2679 45.1769 33.2683 45.182 33.2689C45.1865 33.2695 45.1884 33.2703 45.1894 33.2709L57.455 39.5379C57.7895 39.7089 58 40.0528 58 40.4284V49C58 50.6569 56.6569 52 55 52H18C16.3431 52 15 50.6569 15 49V40.4284C15 40.0528 15.2105 39.7089 15.545 39.5379Z" stroke="#256799" stroke-width="2"/>
            <path d="M47 17.7059C47 21.3288 45.8293 24.9527 43.9567 27.6504C42.075 30.3611 39.5936 32 37 32C34.4064 32 31.925 30.3611 30.0433 27.6504C28.1707 24.9527 27 21.3288 27 17.7059C27 12.3708 31.4514 8 37 8C42.5486 8 47 12.3708 47 17.7059Z" stroke="#256799" stroke-width="2"/>
            <path opacity="0.2" d="M44 45.75C49.5714 45.75 57 42 57 42C57 42 64.4286 45.75 70 45.75V51.375C70 62.625 64.4286 68.25 57 72C49.5714 68.25 44 62.625 44 51.375V45.75Z" fill="#256799"/>
            <path d="M41 47.375V42.715C43.7085 42.5314 46.6402 41.6558 48.9339 40.8137C50.2374 40.3352 51.3621 39.8571 52.1616 39.4983C52.5038 39.3448 52.7871 39.2128 53 39.1116C53.2129 39.2128 53.4962 39.3448 53.8384 39.4983C54.6379 39.8571 55.7626 40.3352 57.0661 40.8137C59.3598 41.6558 62.2915 42.5314 65 42.715V47.375C65 52.8242 63.6545 56.8338 61.4694 59.9224C59.3665 62.8948 56.4352 65.0861 53 66.8762C49.5648 65.0861 46.6335 62.8948 44.5306 59.9224C42.3455 56.8338 41 52.8242 41 47.375Z" fill="white" stroke="#256799" stroke-width="2"/>
            <path d="M46 51L50.6667 56L60 46" stroke="#256799" stroke-width="2"/>
         </svg>
         <span class="size24 bold"><span class="uppercase">Ти один</span> із {{formatNumber(inspectorsData.totalCountLevels || 0)}} <span class="size16 d-block">захисників правди в інтернеті</span></span>
        </div>
      <ul class="level-list unstyle">
         <li class="item">
            <svg class="level-svg">
               <use xlink:href="#level_bronze_active"></use>
            </svg>
            <div class="wrap size16 bold">
               <div class="mb3">1-й рівень</div>
               <div>Бронзових інспекторів<span class="grey dot">•</span><span class="blue">{{getUsersByLevel(1)}}</span></div>
               <div class="progress-line">
                  <div :style="`width: ${getProgressLine(1)}%`" class="percent-line"></div>
               </div>
            </div>
         </li>
         <li class="item">
            <svg class="level-svg"><use xlink:href="#level_silver_active"></use></svg>
            <div class="wrap size16 bold">
               <div class="mb3">2-й рівень</div>
               <div>Срібних інспекторів<span class="grey dot">•</span><span class="blue">{{getUsersByLevel(2)}}</span></div>
               <div class="progress-line">
                  <div :style="`width: ${getProgressLine(2)}%`" class="percent-line"></div>
               </div>
            </div>
         </li>
         <li class="item">
            <svg class="level-svg"><use xlink:href="#level_gold_active"></use></svg>
            <div class="wrap size16 bold">
               <div class="mb3">3-й рівень</div>
               <div>Золотих інспекторів<span class="grey dot">•</span><span class="blue">{{getUsersByLevel(3)}}</span></div>
               <div class="progress-line">
                  <div :style="`width: ${getProgressLine(3)}%`" class="percent-line"></div>
               </div>
            </div>
         </li>
         <li class="item">
            <svg class="level-svg"><use xlink:href="#level_platinum_active"></use></svg>
            <div class="wrap size16 bold">
               <div class="mb3">4-й рівень</div>
               <div>Платинових інспекторів<span class="grey dot">•</span><span :class="getUsersByLevel(4) > 0?'blue':'grey'">{{getUsersByLevel(4)}}</span></div>
               <div class="progress-line">
                  <div :style="`width: ${getProgressLine(4)}%`" class="percent-line"></div>
               </div>
            </div>
         </li>
      </ul>
      <div class="your-info">
         <div class="wrap">
            <svg class="info-svg" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
               <circle opacity="0.2" cx="20" cy="20" r="16" fill="#256799"/>
               <circle cx="16" cy="16" r="15" stroke="#256799" stroke-width="2"/>
               <rect x="15" y="8" width="2" height="2" fill="#256799"/>
               <rect x="15" y="12" width="2" height="12" fill="#256799"/>
            </svg>
            <span v-if="inspectorsData.userData.name" class="info-title bold size16">{{inspectorsData.userData.name || ''}}, саме ти можеш очолити цей рейтинг</span>
         </div>
         <hr>
         <div class="wrap">
            <div class="wrap-ava">
               <!--якщо пустий дів, то покажеться заглушка-->
               <div class="inspector-avatar"><img v-if="inspectorsData.userData.photo" :src="inspectorsData.userData.photo || ''" alt="avatar"></div>
               <svg class="avatar-svg"><use :xlink:href="'#level_'+svgLev(inspectorsData.userData.level)+'_active'"></use></svg>
            </div>
            <ul class="unstyle info-list">
               <li class="item">
                  <div class="count-wrap">
                     <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 12.375V7.715C8.70854 7.53138 11.6402 6.65584 13.9339 5.81373C15.2374 5.33519 16.3621 4.85706 17.1616 4.4983C17.5038 4.34475 17.7871 4.21279 18 4.11163C18.2129 4.21279 18.4962 4.34475 18.8384 4.4983C19.6379 4.85706 20.7626 5.33519 22.0661 5.81373C24.3598 6.65584 27.2915 7.53138 30 7.715V12.375C30 17.8242 28.6545 21.8338 26.4694 24.9224C24.3665 27.8948 21.4352 30.0861 18 31.8762C14.5648 30.0861 11.6335 27.8948 9.53064 24.9224C7.34547 21.8338 6 17.8242 6 12.375Z" stroke="#256799" stroke-opacity="0.4" stroke-width="2"/>
                        <path d="M23 23L18 20L13 23" stroke="#256799" stroke-width="2" stroke-linecap="round"/>
                        <path d="M23 18L18 15L13 18" stroke="#256799" stroke-width="2" stroke-linecap="round"/>
                        <path d="M23 13L18 10L13 13" stroke="#DB5C4C" stroke-width="2" stroke-linecap="round"/>
                     </svg>
                     <div class="count blue-bg">{{inspectorsData.userData.level}}-й</div>
                  </div>
                  <div class="text bold">Рівень</div>
               </li>
               <li class="item">
                  <div class="count-wrap">
                     <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M6.86173 13.5102L6.86174 13.5102L10.2383 8.4453C10.4238 8.1671 10.736 8 11.0704 8H24.9296C25.264 8 25.5762 8.1671 25.7617 8.4453L29.1382 13.5101C29.1383 13.5102 29.1383 13.5102 29.1383 13.5102C29.3423 13.8162 29.6857 14 30.0535 14H31V15V28H27V25V24H26H10H9V25V28H5V15V14H5.94648C6.31427 14 6.65772 13.8162 6.86173 13.5102Z" stroke="#256799" stroke-width="2"/>
                        <path d="M14 15L18 19L26.5 10.5" stroke="#256799" stroke-width="2" stroke-linecap="round"/>
                     </svg>
                     <div class="count red-bg">{{formatNumber(inspectorsData.userData.progress)}}</div>
                  </div>
                  <div class="text bold">Балів</div>
               </li>
               <li class="item">
                  <div class="count-wrap">
                     <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 36H8V6H6V36Z" fill="#256799" fill-opacity="0.4"/>
                        <path d="M17 6H15V36C17 36 15 36 17 36V6Z" fill="#256799" fill-opacity="0.4"/>
                        <path d="M26 6H24V20.4643V36C26 36 19 36 26 36V20.4643V6Z" fill="#256799" fill-opacity="0.4"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M33.9143 0H26.9143V2H30.5L29.3201 3.17998L16.6648 14.0274C17.3303 14.2618 17.8345 14.8385 17.9662 15.546L30.68 4.6485L31.9143 3.41421V7H33.9143V0ZM14.0154 16.1633C14.0053 16.0817 14 15.9986 14 15.9142C14 15.257 14.3171 14.6738 14.8066 14.3092L2 8.7499V11.0786L14.0154 16.1633Z" fill="#256799"/>
                     </svg>
                     <div class="count green-bg">{{formatNumber(inspectorsData.userData.place || '')}}</div>
                  </div>
                  <div class="text bold">Місце</div>
               </li>
               <li class="item">
                  <div class="count-wrap">
                     <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M6 5C4.89543 5 4 5.89543 4 7V31C4 32.1046 4.89543 33 6 33H30C31.1046 33 32 32.1046 32 31V7C32 5.89543 31.1046 5 30 5H6ZM6 13H30V31H6V13ZM6 11V7H30V11H6Z" fill="#256799"/>
                        <path d="M10 3H12V9H10V3Z" fill="#256799"/>
                        <path d="M24 3H26V9H24V3Z" fill="#256799"/>
                        <path d="M24 17H26V19H24V17Z" fill="#256799"/>
                        <path d="M10 17H12V19H10V17Z" fill="#256799"/>
                        <path d="M17 17H19V19H17V17Z" fill="white"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 19C18.5523 19 19 18.5523 19 18C19 17.4477 18.5523 17 18 17C17.4477 17 17 17.4477 17 18C17 18.5523 17.4477 19 18 19ZM18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15C16.3431 15 15 16.3431 15 18C15 19.6569 16.3431 21 18 21Z" fill="#DB5C4C"/>
                        <path d="M24 22H26V24H24V22Z" fill="#256799"/>
                        <path d="M10 22H12V24H10V22Z" fill="#256799"/>
                        <path d="M17 22H19V24H17V22Z" fill="#256799"/>
                        <path d="M24 27H26V29H24V27Z" fill="#256799"/>
                        <path d="M10 27H12V29H10V27Z" fill="#256799"/>
                        <path d="M17 27H19V29H17V27Z" fill="#256799"/>
                     </svg>
                     <div class="count blue-bg">{{mounceIncpect(inspectorsData.userData.daysInspect || '')}}</div>
                  </div>
                  <div class="text bold">Інспектує</div>
               </li>
            </ul>
         </div>
         <div class="size16">До наступного {{getProgressNextLevel.name}} рівня: <span class="bold">{{formatNumber(getProgressNextLevel.val)}} балів</span></div>
      </div>
      <div class="top-list-wrap">
         <h3 class="size18 bold">ТОП найактивніших Інтернет-інспекторів</h3>
         <inspectorsList :inspectorsData="listInspectors"/>
         <a @click="moreRatings" class="btn-blue size16">Дивитись +10 </a>
      </div>
      <div class="inspector-tip">
         <div class="wrap">
            <div class="wrap-ava">
               <!--якщо пустий дів, то покажеться заглушка-->
               <div class="inspector-avatar"><img v-if="inspectorsData.userData.photo" :src="inspectorsData.userData.photo || ''" alt="avat`r"></div>
               <svg class="avatar-svg"><use :xlink:href="'#level_'+svgLev(inspectorsData.userData.level)+'_active'"></use></svg>
            </div>
            <span  class="size16 bold">{{inspectorsData.userData.name || ''}}, найчастіше ти стаєш {{inspectorBadge}}</span>
         </div>
         <p>Спробуй відкрити нові досягнення</p>
         <p>До наступного {{getProgressNextLevel.name}} рівня: <span class="bold">{{getProgressNextLevel.val}} балів</span></p>
         <p>Примножуй бали, поділившись досягненнями  у соцмережах</p>
         <a :href="langPrefix+'/internet-inspector/'" target="_blank" rel="noopener noreferrer" class="btn-blue"
            @click="gaEvent( 'inspector', 'click_on_internet_inspector', 'internet_inspector_rating')"
         >Перевірити мої досягнення</a>
      </div>
   </div>
</template>

<script>

    import {mapGetters,mapActions} from 'vuex';
    import _ from 'lodash'
    import storeModule from '../../store/InspectorsRatings';
    import inspectorsList from  './ListInspectors/index.vue';

    export default {
        name: "InspectorsRating",
        components: {
            inspectorsList
        },
        data(){
            return {
                title: this.lang === 4 ? 'Рейтинг інтернет-інспектора' : 'Рейтинг интернет-инспектора',
                description: this.lang === 4 ? 'ТОП-10 найактивніших інтернет-інспекторів' : 'ТОП-10 самых активных интернет-инспекторов',
                inspectorBadges: [
                    {
                        name: "Інспектором продавців",
                        action: 'impression',
                        resonIds: [1,13,16,4,991]
                    },
                    {
                        name: "Інспектором опису",
                        action: 'impression',
                        resonIds: [11]
                    },

                    {
                        name: "Інспектором пробігу",
                        action: 'impression',
                        resonIds: [14]
                    },
                    {
                        name: "Інспектором пригону",
                        action: 'impression',
                        resonIds: [9],

                    },
                    {
                        name: "Інспектором Дублів",
                        action: 'impression',
                        resonIds: [12]
                    },
                    {
                        name: "Інспектором Відгуків",
                        svg: "inspector_reviews",
                        action: 'review'
                    },
                    {
                        name: "Інспектором покращувачем",
                        resonIds: [992],
                        action: 'impression'
                    },
                    {
                        name: "Інспектором Креш",
                        action: "impression",
                        resonIds: [15]
                    },
                    {
                        name: "Інспектор цін",
                        resonIds: [10],
                        action: 'impression',
                    }
                ]
            }
        },
      init({store, route, context}) {
        store.registerModule('InspectorsRatings', storeModule);
        if (context) {
          const { cookie } = context.request.headers;

          return Promise
              .all([
                store.dispatch('InspectorsRatings/getInspectorsRating',cookie),
                store.dispatch('InspectorsRatings/getMoreRatings',cookie)
              ])
              .then(([{ userData = {} } = {}] = []) => {
                if(!Object.keys(userData).length) {
                  return context.response.redirect(`/login.html?from_url=${context.request.url}`);
                } else if (!userData.level) {
                  return context.response.redirect(`/internet-inspector/`);
                }
              })
        }

      },
		computed: {
            ...mapGetters({
                inspectorsRating: 'InspectorsRatings/inspectorsRatings',
                listInspectors: 'InspectorsRatings/listInspectors',
                blockReq: 'InspectorsRatings/blockReq',
                langPrefix: 'lang/prefix',
				lang: 'lang/id'
            }),
            getProgressNextLevel(){
                let {userData={}} = this.inspectorsRating || {};

                if(userData.progress && userData.progress == 0)
                    return {val:10, name: 'Срібного'}
                else if (userData.progress && userData.progress >= 10 && userData.progress < 1000)
                    return {val: this.formatNumber(1000 - userData.progress), name: 'Золотого'}
                else if (userData.progress && userData.progress >= 1000 && userData.progress < 2000)
                    return {val: this.formatNumber(2000 - userData.progress), name: 'Платинового'}
                else   return {val: 0, name: ''}
            },
            inspectorsData(){
                 let {
                    inspetorsCountByLevel=[], totalCountLevels=0,userData={}} = this.inspectorsRating || {};
                return {inspetorsCountByLevel,totalCountLevels,userData};
            },
            inspectorBadge(){
               let {userData} = this.inspectorsData;
                let {action="", resonId=[]} = userData.aggrHistory || {},
                    badgeName;

                switch (action) {
                    case "review":
                        badgeName = _.find(this.inspectorBadges, history => history.action === action);
                        badgeName = badgeName && badgeName.name || '';
                        break;
                    case "impression":
                        badgeName =  _.find(this.inspectorBadges, item => item.action === action && item.resonIds.indexOf(resonId) >= 0);
                        badgeName = badgeName && badgeName.name || '';
                        break;
                    default: badgeName = '';
                        break;
                }
                return badgeName;
            }
        },
        methods: {
            ...mapActions({
                getInspectorsRating: 'InspectorsRatings/getInspectorsRating',
                getMoreRatings: 'InspectorsRatings/getMoreRatings'
            }),
            svgLev(lev = 0){
                 let levelName;
                 switch (lev) {
                     case 1: levelName='bronze';
                     break;
                     case 2: levelName='silver';
                         break;
                     case 3: levelName='gold';
                         break;
                     case 4: levelName='platinum';
                         break;
                     default: levelName=''
                 }

              return levelName;
            },
            mounceIncpect(daysInspect = 0){
                if (daysInspect && daysInspect > 29){
                    return Math.floor(daysInspect / 30) + ' міс.'
                }else if(daysInspect && daysInspect <= 29) {
                    return daysInspect + ' д.'
                }else {
                    return ''
                }
            },
            moreRatings(){
                if(!this.blockReq){
                    this.gaEvent( 'inspector', 'click_on_more_ratings', 'internet_inspector_rating');
                    this.getMoreRatings();
                }
            },
            getUsersByLevel(level){
                let {inspetorsCountByLevel=[]} = this.inspectorsRating || {};
                let levelObj = inspetorsCountByLevel.find(item => item.level === level);
                return levelObj ? this.formatNumber(levelObj.count) : '';
    },
            getProgressLine(level){
                let {inspetorsCountByLevel=[], totalCountLevels=0} = this.inspectorsData || {};
                let levelObj = inspetorsCountByLevel.find(item => {
                   item.progressLine = item.count*100/totalCountLevels;
                   return item.level === level
                });
               return levelObj ? levelObj.progressLine : 0;

             }
        },
        metaInfo() {
            return {
                title: this.title,
                meta: [
                    {name: 'description', content: this.description},
                    {property: 'og:type', content: "website"},
                    {property: 'og:site_name', content: "AUTO.RIA"},
                    {property: 'og:title', content: this.title},
                    {property: 'og:description', content: this.description},
                    {property: 'og:image', content: 'https://cdn.riastatic.com/photos/pictures/common/1/110/11072/11072l.jpg'},
                    {name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW'}
                ],
                link: [
                    {rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/'},
                    {rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/'},
                    {rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/'},
                    {rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/'},
                    {rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/'},
                    {rel: 'canonical', href: "https://auto.ria.com/"}
                ]
            };
        },
    }
</script>

<style src="./style.sass" lang="sass"></style>


